import type { ReactNode } from "react";

import { Home } from "@mui/icons-material";

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: "General",
    items: [
      {
        name: "Inicio",
        icon: Home,
        link: "/extended-sidebar/dashboards",
      },
    ],
  },
];

export default menuItems;
